@import '../../../../scss/theme-bootstrap';

.utility-nav {
  display: flex;
  justify-content: flex-end;
  &__menu {
    float: $rdirection;
    position: absolute;
    top: 0;
    white-space: normal;
    #{$rdirection}: 0;
    display: flex;
    align-content: space-between;
    gap: 10px;
    @include breakpoint($large-up) {
      position: relative;
      display: block;
    }
  }
  &__smart-menu {
    position: absolute;
    #{$ldirection}: 0;
    width: 100%;
    top: $mobile-nav-height;
    background: $color-white;
    opacity: 1;
    @include breakpoint($large-up) {
      display: none;
    }
  }
  &__search {
    border-bottom: 1px solid $color-black;
    padding: 2px 14px;
  }
  .utility-nav__item {
    list-style: none;
    display: none;
    line-height: 12px;
    font-family: $main-font;
    @include breakpoint($large-up) {
      line-height: 40px;
      display: inline-block;
    }
    .utility-nav__link {
      @include breakpoint($large-up) {
        padding: 0 15px;
        border-#{$rdirection}: 1px solid $color-black;
      }
    }
    &--refer-friend {
      .utility-nav__link {
        p {
          line-height: 12px;
        }
        & > div {
          display: inline-block;
        }
      }
    }
    &--account {
      .utility-nav__link {
        border: none;
      }
    }
    &--search {
      display: block;
    }
    &--cart {
      display: block;
      padding-#{$rdirection}: 14px;
      border: none;
      line-height: $mobile-nav-height;
      order: 2;
      @include breakpoint($large-up) {
        display: inline-block;
        line-height: 12px;
        padding-#{$rdirection}: 30px;
      }
      .utility-nav__link {
        @include breakpoint($large-up) {
          padding: 0;
          border: none;
        }
      }
      .cart_overlay {
        position: fixed;
        width: 100%;
        #{$rdirection}: 0;
        top: 75px;
        @include breakpoint($large-up) {
          top: 35px;
          width: auto;
          position: absolute;
          margin-#{$rdirection}: 27px;
        }
        .cart-confirm-wrapper {
          border: 1px solid $color-black;
          background-color: $color-white;
          .cart-caret {
            display: none;
          }
        }
        a {
          text-decoration: none;
        }
      }
      > a {
        font-size: 15px;
        color: $color-white;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        background: url('/media/images/global/cart_shopping_white.svg') no-repeat 0 0 transparent;
        @include breakpoint($large-up) {
          background: url('/media/images/global/cart_shopping_black.svg') no-repeat 0 0 transparent;
          line-height: 30px;
          height: 37px;
          width: 16px;
          display: block;
        }
        .utility-nav__cart-qty {
          border-radius: 50%;
          background: $color-dark-pink;
          color: $color-white;
          display: inline-block;
          text-align: center;
          width: 14px;
          height: 14px;
          line-height: 14px;
          font-size: 9px;
          position: relative;
          top: -25px;
          #{$ldirection}: 13px;
          visibility: hidden;
          @include breakpoint($large-up) {
            top: -10px;
          }
        }
      }
    }
    &--email {
      .utility-nav__dropdown__content {
        width: 380px;
        .site-email-signup {
          margin-top: auto;
          position: relative;
          &__header {
            width: auto;
          }
          &__form {
            float: none;
            width: 100%;
            input {
              margin-bottom: 18px;
              width: 100%;
            }
          }
        }
      }
    }
    .utility-nav__dropdown {
      opacity: 0;
      visibility: hidden;
      display: none;
      position: absolute;
      z-index: 999;
      top: 35px;
      #{$rdirection}: 30px;
      height: auto;
      line-height: normal;
      color: $color-black;
      white-space: normal;
      &__content {
        background-color: $color-white;
        border: 1px solid $color-black;
      }
    }
    &.active {
      .utility-nav__dropdown {
        opacity: 1;
        visibility: visible;
        display: block;
      }
      .utility-nav__link-text {
        color: $color-dark-pink;
      }
    }
    a {
      text-decoration: none;
      font-size: 14px;
      p {
        margin: auto;
      }
    }
  }
}

.cart_overlay {
  a {
    text-decoration: none;
  }
  @include transition(opacity 0.4s, visibility 0.4s);
  visibility: visible;
  opacity: 1;
  position: absolute;
  z-index: 1000;
  top: 45px;
  #{$rdirection}: 3px;
  width: 437px;
  @include breakpoint($small-down) {
    #{$rdirection}: -65px;
    top: 75px;
    width: 320px;
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;
    display: block !important; // !important wars...
  }
  .cart-caret {
    background: url('/media/images/background-images/background-image1.png')
      no-repeat 0 0;
    position: absolute;
    top: -12px;
    #{$rdirection}: 12px;
    width: 22px;
    height: 12px;
    @include breakpoint($small-down) {
      #{$rdirection}: 67px;
    }
  }
  .cart-confirm-wrapper {
    position: relative;
    width: 437px;
    border: solid 1px $color-gray;
    background-color: $color-white;
    color: $color-black;
    z-index: 1;
    @include breakpoint($small-only) {
      width: 100%;
    }
    .close {
      background: url('/media/images/background-images/background-image2.png')
        no-repeat 0 0;
      width: 18px;
      height: 18px;
      display: block;
      color: transparent;
      text-indent: -10000px;
      float: $rdirection;
      margin: 10px 14px;
    }
    h2 {
      margin: 20px;
      font-size: 14px;
      font-family: $main-font;
      font-weight: 500;
      letter-spacing: 0;
      text-align: $ldirection;
      text-transform: uppercase;
    }
    .has-items-in-bag {
      width: 436px;
      @include breakpoint($small-down) {
        width: 100%;
      }
    }
    .products-heading {
      display: none; /* BB-203 */
      span {
        font-family: $main-font;
        font-weight: normal;
        font-size: 14px;
        text-transform: none;
        &.h_product {
          @include swap_direction(padding, 0 210px 0 20px);
          @include breakpoint($small-down) {
            float: $ldirection;
            @include swap_direction(padding, 0 0 0 10px);
            text-align: $ldirection;
            width: 56%;
          }
        }
        &.h_qty {
          @include swap_direction(padding, 0 40px 0 0);
          @include breakpoint($small-down) {
            margin-#{$rdirection}: 2%;
            padding: 0;
            width: 6%;
          }
        }
      }
    }
    .no-items-in-bag {
      height: auto;
      max-height: 320px;
      width: 436px;
      @include breakpoint($small-down) {
        width: 100%;
        padding: 0 10px;
      }
      overflow-x: hidden;
      overflow-y: auto;
      background: url('/media/images/background-images/background-image3.png')
        repeat-x 0 0 $color-white;
      padding: 0 20px 10px;
      text-align: $ldirection;
      a.button {
        margin-#{$ldirection}: 5px;
      }
    }
    .cart-products {
      height: auto;
      max-height: 320px;
      width: 436px;
      overflow-x: hidden;
      overflow-y: auto;
      @include swap_direction(padding, 0 22px 0 20px);
      @include breakpoint($small-down) {
        width: 100%;
        padding: 0 10px;
        max-height: 170px;
      }
      .prod {
        width: 396px;
        @include breakpoint($small-down) {
          width: 100%;
        }
        padding: 10px 0;
        line-height: 1.1;
        border-bottom: solid 1px $color-gray;
        a {
          color: $color-black;
        }
        img {
          margin-#{$rdirection}: 20px;
          float: $ldirection;
          @include breakpoint($small-down) {
            width: 20%;
            margin-#{$rdirection}: 2%;
          }
        }
        &:first-child {
          border-top: solid 1px $color-gray;
        }
        .prod-info {
          float: $ldirection;
          width: 190px;
          text-align: $ldirection;
          @include breakpoint($small-down) {
            width: 48%;
            margin-#{$rdirection}: 2%;
            padding-top: 0;
          }
          a,
          .cart-product-name {
            @include main-font-medium;
            display: block;
            font-size: 12px;
            font-family: $main-font;
            letter-spacing: 0;
            text-transform: uppercase;
            text-align: $ldirection;
          }
          .size {
            font-family: $main-font;
            text-transform: none;
            font-size: 12px;
          }
          .shade_info {
            text-transform: none;
            font-family: $main-font;
            font-size: 12px;
            height: 32px;
            padding-top: 8px;
            .shade_thumb {
              width: 22px;
              height: 22px;
              background: $color-gray;
              border-radius: 50%;
              transition: all 0.2s ease-in-out;
              display: block;
              float: $ldirection;
              @include swap_direction(margin, 2px 5px 0 0);
            }
            span {
              display: inline-block;
              padding-top: 4px;
            }
          }
        }
        .qty {
          @include main-font-medium;
          float: $ldirection;
          font-size: 12px;
          padding-top: 7px;
          @include breakpoint($small-down) {
            width: 6%;
            margin-#{$rdirection}: 2%;
            padding-top: 0;
          }
        }
        .price {
          font-size: 12px;
          @include main-font-bold;
          letter-spacing: 0;
          text-transform: uppercase;
          line-height: 1;
          float: $rdirection;
          text-align: $rdirection;
          padding-top: 7px;
          @include breakpoint($small-down) {
            width: 20%;
            padding-top: 0;
          }
          padding-#{$rdirection}: 0;
          font-weight: bold;
          .currency_type {
            clear: both;
          }
        }
      }
      .view_bag {
        text-align: center;
        padding: 10px;
        a {
          color: $color-black;
          border-bottom: solid 1px $color-black;
          font-size: 14px;
        }
      }
    }
    .subtotal {
      @include main-font-bold;
      @include swap_direction(padding, 0 22px 0 20px);
      line-height: 1;
      font-size: 12px;
      letter-spacing: 0;
      text-transform: uppercase;
      .items {
        .items-in-cart {
          display: none;
        }
        .item-in-cart {
          display: inline;
        }
        &.plural {
          .item-in-cart {
            display: none;
          }
          .items-in-cart {
            display: inline;
          }
        }
      }
      .left-copy {
        float: $ldirection;
      }
      .right-copy {
        float: $rdirection;
        text-align: $rdirection;
        .sub_t_text {
          float: $ldirection;
        }
        .sub_t {
          float: $rdirection;
          width: 73px;
        }
      }
    }
    .checkout {
      @include swap_direction(padding, 20px 22px 20px 20px);
      text-align: $rdirection;
      clear: both;
      margin: 0;
      .button {
        letter-spacing: 0;
      }
    }
    .promos {
      width: 100%;
      /* default free shipping */
      .free_ship {
        width: 100%;
        height: 55px;
        background: $color-black;
        color: $color-white;
        text-align: center;
        h2 {
          color: $color-white;
          padding-top: 7px;
          letter-spacing: 4px;
          line-height: 20px;
          font-family: $main-font;
          margin-bottom: 0;
          text-align: center;
        }
        p {
          text-transform: none;
          font-family: $main-font;
          font-size: 12px;
        }
      }
    }
    /* add view only */
    .add_view {
      h2 {
        color: $color-pink;
        font-family: $main-font;
        font-size: 14px;
        text-transform: none;
      }
      .cart-products {
        background: none;
        .prod {
          border-top: solid 1px $color-gray;
          border-bottom: none;
        }
      }
      .checkout {
        padding: 20px 20px 0;
        text-align: $rdirection;
        clear: both;
      }
    }
    /* loading view */
    &.loading {
      text-align: center;
      padding: 20px;
    }
  }
}

